/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { StyleColorSocialDribbble } from "../../icons/StyleColorSocialDribbble";
import { StyleColorSocialFacebook } from "../../icons/StyleColorSocialFacebook";
import { StyleColorSocialInstagram } from "../../icons/StyleColorSocialInstagram";
import { StyleColorSocialLinkedin } from "../../icons/StyleColorSocialLinkedin";
import { StyleGraySocialBehance } from "../../icons/StyleGraySocialBehance";
import { StyleGraySocialInstagram } from "../../icons/StyleGraySocialInstagram";
import { StyleGraySocialTwitter } from "../../icons/StyleGraySocialTwitter";
import { StyleWhiteSocialFacebook } from "../../icons/StyleWhiteSocialFacebook";
import { StyleWhiteSocialGithub } from "../../icons/StyleWhiteSocialGithub";
import { StyleWhiteSocialPinterest } from "../../icons/StyleWhiteSocialPinterest";
import { StyleWhiteSocialYoutube } from "../../icons/StyleWhiteSocialYoutube";
import "./style.css";

export const SocialIcons = ({ style, social }) => {
  return (
    <>
      {((social === "behance" && style === "primary") ||
        (social === "dribbble" && style === "primary") ||
        (social === "facebook" && style === "primary") ||
        (social === "instagram" && style === "primary") ||
        (social === "linked-in" && style === "primary") ||
        (social === "pinterest" && style === "primary") ||
        (social === "twitter" && style === "primary") ||
        (social === "youtube" && style === "primary")) && (
        <img
          className="social-icons"
          alt="Style primary social"
          src={
            social === "linked-in"
              ? "/img/style-primary-social-linkedin.png"
              : social === "facebook"
              ? "/img/style-primary-social-facebook.png"
              : social === "dribbble"
              ? "/img/style-primary-social-dribbble.png"
              : social === "instagram"
              ? "/img/style-primary-social-instagram.png"
              : social === "youtube"
              ? "/img/style-primary-social-youtube.png"
              : social === "behance"
              ? "/img/style-primary-social-behance.png"
              : social === "pinterest"
              ? "/img/style-primary-social-pinterest.png"
              : "/img/style-primary-social-twitter.png"
          }
        />
      )}

      {social === "twitter" && ["color", "gray", "white"].includes(style) && (
        <StyleGraySocialTwitter
          className="social-icons"
          color={style === "gray" ? "#0B0D17" : style === "white" ? "white" : "#55ACEE"}
          fill={style === "gray" ? "#969BAB" : "white"}
          opacity={["gray", "white"].includes(style) ? "0.1" : undefined}
        />
      )}

      {social === "linked-in" && ["color", "gray", "white"].includes(style) && (
        <StyleColorSocialLinkedin
          className="social-icons"
          color={style === "gray" ? "#0B0D17" : style === "white" ? "white" : "#0077B5"}
          fill={style === "gray" ? "#969BAB" : "white"}
          opacity={["gray", "white"].includes(style) ? "0.1" : undefined}
        />
      )}

      {social === "facebook" && ["gray", "white"].includes(style) && (
        <StyleWhiteSocialFacebook
          className="social-icons"
          color={style === "white" ? "white" : "#0B0D17"}
          fill={style === "white" ? "white" : "#969BAB"}
        />
      )}

      {social === "dribbble" && ["color", "gray", "white"].includes(style) && (
        <StyleColorSocialDribbble
          className="social-icons"
          color={style === "gray" ? "#0B0D17" : style === "white" ? "white" : "#EA4C89"}
          fill={style === "gray" ? "#969BAB" : "white"}
          opacity={["gray", "white"].includes(style) ? "0.1" : undefined}
        />
      )}

      {social === "instagram" && ["gray", "white"].includes(style) && (
        <StyleGraySocialInstagram
          className="social-icons"
          color={style === "white" ? "white" : "#0B0D17"}
          fill={style === "white" ? "white" : "#969BAB"}
        />
      )}

      {social === "youtube" && ["color", "gray", "white"].includes(style) && (
        <StyleWhiteSocialYoutube
          className="social-icons"
          color={style === "gray" ? "#0B0D17" : style === "white" ? "white" : "#FF0000"}
          fill={style === "gray" ? "#969BAB" : "white"}
          opacity={["gray", "white"].includes(style) ? "0.1" : undefined}
        />
      )}

      {social === "behance" && ["color", "gray", "white"].includes(style) && (
        <StyleGraySocialBehance
          className="social-icons"
          color={style === "gray" ? "#0B0D17" : style === "white" ? "white" : "#1769FF"}
          fill={style === "gray" ? "#969BAB" : "white"}
          opacity={["gray", "white"].includes(style) ? "0.1" : undefined}
        />
      )}

      {social === "pinterest" && ["color", "gray", "white"].includes(style) && (
        <StyleWhiteSocialPinterest
          className="social-icons"
          color={style === "gray" ? "#0B0D17" : style === "white" ? "white" : "#E60019"}
          fill={style === "gray" ? "#969BAB" : "white"}
          opacity={["gray", "white"].includes(style) ? "0.1" : undefined}
        />
      )}

      {social === "github" && (
        <StyleWhiteSocialGithub
          className="social-icons"
          color={style === "color" ? "#161514" : style === "gray" ? "#0B0D17" : style === "white" ? "white" : "#8C30F5"}
          fill={style === "gray" ? "#969BAB" : "white"}
          opacity={["gray", "white"].includes(style) ? "0.1" : undefined}
        />
      )}

      {social === "instagram" && style === "color" && <StyleColorSocialInstagram className="social-icons" />}

      {style === "color" && social === "facebook" && <StyleColorSocialFacebook className="social-icons" />}
    </>
  );
};

SocialIcons.propTypes = {
  style: PropTypes.oneOf(["primary", "gray", "white", "color"]),
  social: PropTypes.oneOf([
    "facebook",
    "pinterest",
    "twitter",
    "dribbble",
    "youtube",
    "behance",
    "linked-in",
    "instagram",
    "github",
  ]),
};
