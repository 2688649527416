/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const Logo = ({ className }) => {
  return (
    <div className={`logo ${className}`}>
      <p className="flowalytics">
        <span className="text-wrapper">Flow</span>
        <span className="span">alytics</span>
      </p>
      <img className="whatsapp-image" alt="Whatsapp image" src="/img/whatsapp-image-2023-11-14-at-17-59-1.png" />
    </div>
  );
};
