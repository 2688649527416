/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const StyleWhiteSocialGithub = ({ color = "white", opacity = "unset", fill = "white", className }) => {
  return (
    <svg
      className={`style-white-social-github ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="circle" cx="16" cy="16" fill={color} opacity={opacity} r="16" />
      <path
        className="path"
        d="M16.0001 4C9.3735 4 4 9.50847 4 16.3038C4 21.74 7.43839 26.3519 12.2064 27.9789C12.8061 28.0927 13.0263 27.7119 13.0263 27.387C13.0263 27.0936 13.0152 26.1244 13.01 25.0963C9.67157 25.8405 8.96712 23.6446 8.96712 23.6446C8.42125 22.2224 7.63473 21.8443 7.63473 21.8443C6.54596 21.0806 7.7168 21.0963 7.7168 21.0963C8.92181 21.1831 9.55632 22.3642 9.55632 22.3642C10.6266 24.2452 12.3636 23.7014 13.0484 23.387C13.1561 22.5918 13.4671 22.049 13.8102 21.7418C11.1449 21.4307 8.34295 20.3757 8.34295 15.6612C8.34295 14.3179 8.81172 13.2203 9.57937 12.3587C9.45477 12.0488 9.04402 10.7974 9.69562 9.10261C9.69562 9.10261 10.7033 8.77193 12.9965 10.3638C13.9537 10.0912 14.9803 9.95447 16.0001 9.94979C17.0199 9.95447 18.0473 10.0912 19.0063 10.3638C21.2967 8.77193 22.303 9.10261 22.303 9.10261C22.9562 10.7974 22.5452 12.0488 22.4206 12.3587C23.1901 13.2203 23.6557 14.3179 23.6557 15.6612C23.6557 20.3869 20.8484 21.4274 18.1762 21.732C18.6067 22.1138 18.9902 22.8626 18.9902 24.0105C18.9902 25.6568 18.9763 26.9817 18.9763 27.387C18.9763 27.7144 19.1923 28.098 19.8006 27.9772C24.566 26.3485 28 21.7381 28 16.3038C28 9.50847 22.6273 4 16.0001 4Z"
        fill={fill}
      />
      <path
        className="path"
        d="M8.58043 21.5812C8.55405 21.6417 8.46043 21.6598 8.37514 21.6183C8.28827 21.5785 8.23947 21.496 8.26764 21.4353C8.29342 21.373 8.38724 21.3556 8.47392 21.3974C8.56099 21.4371 8.61058 21.5204 8.58043 21.5812Z"
        fill={fill}
      />
      <path
        className="path"
        d="M9.06559 22.1315C9.00846 22.1853 8.89679 22.1603 8.82103 22.0752C8.74268 21.9903 8.728 21.8767 8.78592 21.822C8.84483 21.7682 8.95312 21.7934 9.03167 21.8783C9.11002 21.9642 9.12529 22.077 9.06559 22.1315Z"
        fill={fill}
      />
      <path
        className="path"
        d="M9.53784 22.8331C9.46445 22.885 9.34445 22.8364 9.27027 22.728C9.19688 22.6197 9.19688 22.4898 9.27186 22.4377C9.34624 22.3857 9.46445 22.4325 9.53963 22.54C9.61282 22.6502 9.61282 22.7801 9.53784 22.8331Z"
        fill={fill}
      />
      <path
        className="path"
        d="M10.1848 23.511C10.1192 23.5846 9.97936 23.5648 9.87701 23.4644C9.77229 23.3661 9.74313 23.2267 9.80898 23.1531C9.87543 23.0793 10.0161 23.1 10.1192 23.1997C10.2231 23.2977 10.2549 23.4381 10.1848 23.511Z"
        fill={fill}
      />
      <path
        className="path"
        d="M11.0774 23.9046C11.0485 24 10.9138 24.0433 10.7781 24.0028C10.6426 23.961 10.554 23.8493 10.5813 23.7528C10.6095 23.6568 10.7448 23.6116 10.8814 23.655C11.0167 23.6966 11.1056 23.8075 11.0774 23.9046Z"
        fill={fill}
      />
      <path
        className="path"
        d="M12.0576 23.9776C12.061 24.0781 11.946 24.1614 11.8036 24.1632C11.6604 24.1664 11.5445 24.0851 11.5429 23.9863C11.5429 23.8848 11.6554 23.8023 11.7986 23.7999C11.941 23.797 12.0576 23.8777 12.0576 23.9776Z"
        fill={fill}
      />
      <path
        className="path"
        d="M12.9699 23.8196C12.9869 23.9177 12.8879 24.0183 12.7465 24.0452C12.6075 24.071 12.4787 24.0105 12.4611 23.9132C12.4438 23.8128 12.5446 23.7121 12.6834 23.6861C12.8251 23.6611 12.9518 23.72 12.9699 23.8196Z"
        fill={fill}
      />
    </svg>
  );
};

StyleWhiteSocialGithub.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.string,
  fill: PropTypes.string,
};
