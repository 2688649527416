/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./style.css";

export const CarousselVideoDemo = ({ className }) => {
  const [propertyIndex, setPropertyIndex] = useState(0);
  const properties = ["predict", "advanced", "regulatory"];

  useEffect(() => {
    const interval = setInterval(() => {
      setPropertyIndex((prevIndex) => (prevIndex + 1) % properties.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const property1 = properties[propertyIndex];
  return (
    <div className={`caroussel-video-demo ${property1} ${className}`}>
      <div className="frame">
        {property1 === "advanced" && (
          <p className="advanced-analytics">
            <span className="text-wrapper-2">Advanced Analytics</span>
            <span className="span-2"> Capabilities &amp; </span>
            <span className="text-wrapper-2">Real-time</span>
            <span className="span-2"> Data Visualization</span>
          </p>
        )}

        {["predict", "regulatory"].includes(property1) && (
          <div className="predict-customer">
            <span className="span-2">
              {property1 === "regulatory" && <>Regulatory </>}

              {property1 === "predict" && <>Predict </>}
            </span>
            <span className="span-3">
              {property1 === "predict" && <>customer profits</>}

              {property1 === "regulatory" && <>Reaction Forecasting</>}
            </span>
            {property1 === "predict" && (
              <>
                <p className="span-wrapper">
                  <span className="span-2"> and </span>
                </p>
                <p className="span-wrapper">
                  <span className="text-wrapper-3">income</span>
                </p>
              </>
            )}
          </div>
        )}

        <div className="access-powerful">
          <span className="span-4">
            {property1 === "regulatory" && (
              <>
                Forecast regulatory responses across various industries, helping
                you{" "}
              </>
            )}

            {property1 === "advanced" && <>Access </>}

            {property1 === "predict" && <>Utilize various factors such as </>}
          </span>
          <span className="span-5">
            {property1 === "advanced" && <>powerful analytics tools</>}

            {property1 === "predict" && <>economic performance</>}

            {property1 === "regulatory" && (
              <>navigate complex regulatory landscapes</>
            )}
          </span>
          <span className="span-4">
            {property1 === "advanced" && (
              <>
                {" "}
                that go beyond basic reporting, allowing you to uncover hidden
                patterns, trends, and correlations within your data also{" "}
              </>
            )}

            {property1 === "predict" && <>, </>}

            {property1 === "regulatory" && (
              <> and make informed compliance decisions.</>
            )}
          </span>
          {["advanced", "predict"].includes(property1) && (
            <>
              <span className="span-5">
                {property1 === "advanced" && (
                  <>visualize your data in real-time</>
                )}

                {property1 === "predict" && <>interest rates</>}
              </span>
              <span className="span-4">
                {property1 === "advanced" && <>, enabling you to gain </>}

                {property1 === "predict" && <>, and </>}
              </span>
              <span className="span-5">
                {property1 === "advanced" && <>instant insights</>}

                {property1 === "predict" && <>market activity</>}
              </span>
              <span className="span-4">
                {property1 === "advanced" && (
                  <> and react quickly to changing market conditions.</>
                )}

                {property1 === "predict" && (
                  <> to accurately forecast customer profits and income.</>
                )}
              </span>
            </>
          )}
        </div>
        {property1 === "advanced" && (
          <div className="dot">
            <div className="ellipse" />
            <div className="ellipse-2" />
            <div className="ellipse" />
          </div>
        )}
      </div>
      {["predict", "regulatory"].includes(property1) && (
        <div className="dot">
          <div className="ellipse-3" />
          <div className="ellipse" />
          <div className="ellipse-4" />
        </div>
      )}
    </div>
  );
};

CarousselVideoDemo.propTypes = {
  property1: PropTypes.oneOf(["predict", "advanced", "regulatory"]),
};
