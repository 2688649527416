import React from "react";
import { Button } from "../../components/Button";
import { CarousselVideoDemo } from "../../components/CarousselVideoDemo";
import { EssentialIconsSendWrapper } from "../../components/EssentialIconsSendWrapper";
import { Logo } from "../../components/Logo";
import { SocialIcons } from "../../components/SocialIcons";
import "./style.css";
import { Link, Element } from "react-scroll";
export const LandiingPage = () => {
  return (
    <div className="landiing-page">
      <div className="div-2">
        <footer className="footer">
          <div className="footer-big">
            <div className="company-info">
              <Logo />
              <div className="copyright">
                <div className="text-wrapper-4">
                  Copyright © 2023 Flowalytics.
                </div>
                <div className="text-wrapper-5">All rights reserved</div>
              </div>
              <div className="social-links">
                <SocialIcons social="instagram" style="white" />
                <SocialIcons social="dribbble" style="white" />
                <SocialIcons social="twitter" style="white" />
                <SocialIcons social="youtube" style="white" />
              </div>
            </div>
            <div className="links">
              <div className="col">
                <div className="text-wrapper-6">Company</div>
                <div className="list-items">
                  <div className="text-wrapper-7">About us</div>
                  <div className="text-wrapper-8">Contact us</div>
                  <div className="text-wrapper-8">Pricing</div>
                  <div className="text-wrapper-8">Case Studies</div>
                  <div className="text-wrapper-8">Testimonials</div>
                </div>
              </div>
              <div className="col">
                <div className="text-wrapper-6">Support</div>
                <div className="list-items">
                  <div className="text-wrapper-7">Technical Support</div>
                  <div className="text-wrapper-8">Help center</div>
                  <div className="text-wrapper-8">Terms of service</div>
                  <div className="text-wrapper-8">Legal</div>
                  <div className="text-wrapper-8">Privacy policy</div>
                </div>
              </div>
              <div className="col-2">
                <div className="group">
                  <p className="p">
                    Cohive 101, Jl. DR. Ide Anak Agung Gde Agung, Kawasan Mega
                    Kuningan Jakarta Selatan, DKI Jakarta
                  </p>
                  <img
                    className="map-pin"
                    alt="Map pin"
                    src="/img/map-pin.png"
                  />
                </div>
                <div className="text-wrapper-9">Stay up to date</div>
                <div className="email-input-field">
                  <div className="overlap-group">
                    <div className="input-field-BG" />
                    <EssentialIconsSendWrapper />
                    <div className="text-wrapper-10">Your email address</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="overlap">
          <div className="frame-2">
            <div className="home">
              <Element name="home" className="element">
                <div className="hero-section">
                  <div className="overlap-2">
                    <div className="overlap-3">
                      <div className="frame-3">
                        <div className="text">
                          <p className="start-a-new">
                            <span className="text-wrapper-11">
                              Start a new experience in{" "}
                            </span>
                            <span className="text-wrapper-12">
                              processing data
                            </span>
                          </p>
                        </div>
                        <Button
                          className="button-instance"
                          divClassName="design-component-instance-node"
                          icon="right"
                          size="medium"
                          state="default"
                          text="Register"
                          type="primary"
                        />
                      </div>
                      <div className="ellipse-5" />
                      <div className="ellipse-6" />
                      <img
                        className="vector"
                        alt="Vector"
                        src="/img/vector-8.svg"
                      />
                      <img
                        className="img"
                        alt="Vector"
                        src="/img/vector-9.svg"
                      />
                      <img
                        className="https-lottiefiles"
                        alt="Https lottiefiles"
                        src="/img/https-lottiefiles-com-animations-analytics-computer-omolhvxo13.gif"
                      />
                      <img className="group-2" alt="Group" src="/img/4.png" />
                      <div className="overlap-wrapper">
                        <div className="overlap-4">
                          <div className="rectangle" />
                          <div className="group-3">
                            <div className="overlap-group-wrapper">
                              <div className="overlap-group-2">
                                <img
                                  className="ellipse-7"
                                  alt="Ellipse"
                                  src="/img/ellipse-974.png"
                                />
                                <img
                                  className="ellipse-8"
                                  alt="Ellipse"
                                  src="/img/ellipse-975-1.png"
                                />
                                <img
                                  className="ellipse-9"
                                  alt="Ellipse"
                                  src="/img/ellipse-976-1.png"
                                />
                                <img
                                  className="ellipse-10"
                                  alt="Ellipse"
                                  src="/img/ellipse-977-1.png"
                                />
                              </div>
                            </div>
                            <div className="div-wrapper">
                              <div className="overlap-5">
                                <div className="text-wrapper-13">40k +</div>
                                <div className="companies">companies</div>
                              </div>
                            </div>
                          </div>
                          <div className="akar-icons-heart-wrapper">
                            <img
                              className="akar-icons-heart"
                              alt="Akar icons heart"
                              src="/img/akar-icons-heart-1.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dot-2">
                      <div className="ellipse-11" />
                      <div className="ellipse-12" />
                      <div className="ellipse-12" />
                    </div>
                  </div>
                </div>
              </Element>
              <Element name="clients" className="element">
                <div className="clients">
                  <div className="clients-2">
                    <p className="section-title">
                      <span className="text-wrapper-14">Our </span>
                      <span className="text-wrapper-15">Clients</span>
                    </p>
                    <p className="description">
                      We have been working with some extraordinary clients
                    </p>
                  </div>
                  <div className="clients-logos">
                    <div className="clients-logos-2">
                      <img
                        className="logo-of-bank-BTPN"
                        alt="Logo of bank BTPN"
                        src="/img/logo-of-bank-btpn-1.svg"
                      />
                      <img
                        className="whatsapp-image-2"
                        alt="Whatsapp image"
                        src="/img/whatsapp-image-2023-11-16-at-12-47-1.svg"
                      />
                      <img
                        className="download-removebg"
                        alt="Download removebg"
                        src="/img/download-removebg-preview-1.png"
                      />
                      <img
                        className="bannner-depan"
                        alt="Bannner depan"
                        src="/img/bannner-depan-1.png"
                      />
                    </div>
                  </div>
                </div>
              </Element>

              <div className="community">
                <div className="feature-wrapper">
                  <div className="feature">
                    <div className="title">
                      <div className="feature-2">
                        <div className="text-wrapper-16">
                          What advantages we offer?
                        </div>
                      </div>
                    </div>
                    <p className="section-title-2">
                      Solve more of your data problems with less code, less time
                    </p>
                    <div className="content">
                      <div className="element">
                        <img className="icon" alt="Icon" src="/img/icon.png" />
                        <div className="content-2">
                          <div className="text-2">
                            <div className="text-wrapper-17">
                              Simple and Less Code
                            </div>
                            <p className="text-wrapper-18">
                              Make it easier&nbsp;&nbsp;to process data because
                              it does not require coding
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="element-2">
                        <div className="content-3">
                          <div className="text-2">
                            <div className="text-wrapper-19">
                              Self Service Analytics
                            </div>
                            <p className="text-wrapper-18">
                              can create their own analytical models, allowing
                              business users to perform their own daily
                              analytical tasks without involving the IT team
                            </p>
                          </div>
                        </div>
                        <img
                          className="icon-2"
                          alt="Icon"
                          src="/img/icon-1.png"
                        />
                      </div>
                      <div className="element-3">
                        <div className="content-4">
                          <div className="text-2">
                            <div className="text-wrapper-19">
                              Online Web Based
                            </div>
                            <p className="text-wrapper-18">
                              Can be accessed online, no installation required
                              because it is web based
                            </p>
                          </div>
                        </div>
                        <img
                          className="icon-3"
                          alt="Icon"
                          src="/img/icon-2.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-4">
              <div className="unlock">
                <div className="unlock-2">
                  <img
                    className="visual-data-pana"
                    alt="Visual data pana"
                    src="/img/visual-data-pana-1.svg"
                  />
                  <div className="frame-5">
                    <div className="frame-6">
                      <p className="get-to-know">
                        <span className="text-wrapper-14">Get to know </span>
                        <span className="text-wrapper-15">Flowalytics</span>
                        <span className="text-wrapper-14">
                          {" "}
                          in easy use of{" "}
                        </span>
                        <span className="text-wrapper-15">Data Analysis</span>
                      </p>
                      <p className="when-joining-the">
                        <span className="text-wrapper-20">Flowalytics</span>
                        <span className="text-wrapper-21">
                          {" "}
                          is a SaaS (Software as a Service) for analyzing,
                          modeling, predicting and forecasting data with zero
                          code (block based) so there is no need to learn
                          programming languages to process data.{" "}
                        </span>
                        <span className="text-wrapper-20">Flowalytics</span>
                        <span className="text-wrapper-21">
                          {" "}
                          is our innovative solution designed to tackle this
                          challenge head on by introducing a state of the art
                          Low Code Data Analytics Tool. <br />
                          <br />
                        </span>
                        <span className="text-wrapper-22">
                          keyword: Self Service Analytics, Low Code
                        </span>
                      </p>
                    </div>
                    <Button
                      className="button-instance"
                      divClassNameOverride="design-component-instance-node"
                      icon="no"
                      size="medium"
                      state="default"
                      text="Learn More"
                      type="primary"
                    />
                  </div>
                </div>
              </div>
              <div className="users">
                <div className="asset">
                  <div className="overlap-group-3">
                    <img
                      className="rectangle-2"
                      alt="Rectangle"
                      src="/img/rectangle-72.svg"
                    />
                    <p className="helping-your-company">
                      <span className="text-wrapper-14">
                        Helping your company
                      </span>
                      <span className="text-wrapper-23"> to analyze data</span>
                    </p>
                    <div className="frame-7">
                      <div className="text-wrapper-24">2,200+</div>
                      <div className="text-wrapper-25">Users</div>
                    </div>
                    <div className="frame-8">
                      <div className="text-wrapper-26">20+</div>
                      <div className="text-wrapper-25">Study Case</div>
                    </div>
                    <div className="frame-9">
                      <div className="text-wrapper-27">46,325+</div>
                      <div className="text-wrapper-28">Data Processed</div>
                    </div>
                    <img
                      className="connected-world-pana"
                      alt="Connected world pana"
                      src="/img/connected-world-pana-1.svg"
                    />
                  </div>
                </div>
              </div>
              <Element name="solutions" className="element">
                <div className="frame-10">
                  <div className="overlap-6">
                    <div className="overlap-7">
                      <img
                        className="rectangle-3"
                        alt="Rectangle"
                        src="/img/rectangle-751.svg"
                      />
                      <img
                        className="rectangle-4"
                        alt="Rectangle"
                        src="/img/rectangle-752.svg"
                      />
                      {/* <video
                        className="frame-11"
                        autoPlay
                        loop
                        muted
                        playsInline
                      >
                        <source
                          src="https://s3-figma-videos-production-sig.figma.com/video/1354664815768310215/TEAM/5a23/49fd/-177c-46b5-baca-671b17a63b38?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DtkqsIltYOsnQ6r76m7cGwA9QqIBAQLdFW3AWNObdc23yjNiYnBaVmTkMA2yQV36ai4lxhmg6-MMYSJU~2k1JNfvXjvr8HJRNhvBUq4OfPTxn6HxKbXsYbzCaWNxAYL6PeRzkSAIIjIcas~MHPTFe~bu-cplu~lXnHR6gFDaIC0MeHEW3yBra5pp1P2v6Gmj2u5XuNrmtZZQjpJO361mx-hrZwfbdXiKBKeoa-KfZwHWyOsY0vbv2zMDDpbRsN-y1DZ04wA6xDv2nk~U6gUFEEU8i7ypvOS9aw3kW7ZH2HMLWfMEKbT~mX3lQYgBGn4GrBUjrV-agHzKTCwUPc3ulg__"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video> */}
                      <img
                        src="/img/iterative.gif"
                        className="frame-11"
                        alt="Heheheheh"
                      />
                      <CarousselVideoDemo className="caroussel-video-demo-instance" />
                      <div className="asset-2">
                        <img
                          className="ellipse-13"
                          alt="Ellipse"
                          src="/img/ellipse-24.svg"
                        />
                        <img
                          className="ellipse-14"
                          alt="Ellipse"
                          src="/img/ellipse-24.svg"
                        />
                        <img className="img-2" alt="Img" src="/img/3.svg" />
                        <img className="img-3" alt="Img" src="/img/3.svg" />
                        <img className="img-4" alt="Img" src="/img/2.svg" />
                        <img className="img-5" alt="Img" src="/img/3.svg" />
                      </div>
                    </div>
                    <p className="our-solution">
                      <span className="text-wrapper-29">Our </span>
                      <span className="text-wrapper-15">Solution</span>
                    </p>
                    <img
                      className="ultra-whimsy-line"
                      alt="Ultra whimsy line"
                      src="/img/ultra-whimsy-line-03.svg"
                    />
                  </div>
                </div>
              </Element>
              <Element name="team" className="element">
                <div className="group-wrapper">
                  <div className="group-4">
                    <div className="frame-12">
                      <p className="our-expert-team">
                        <span className="text-wrapper-30">Our </span>
                        <span className="text-wrapper-23">expert team</span>
                      </p>
                      <p className="text-wrapper-31">
                        We need talented, passionate people to changing the
                        world
                      </p>
                    </div>
                    <div className="group-5">
                      <div className="overlap-group-4">
                        <img
                          className="mask-group"
                          alt="Mask group"
                          src="/img/mask-group.png"
                        />
                        <div className="rectangle-5" />
                        <div className="frame-13">
                          <div className="text-wrapper-32">
                            Heru Wiryanto, Ph.D.
                          </div>
                          <div className="text-wrapper-33">
                            Senior AI Scientist
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-6">
                      <div className="overlap-group-4">
                        <img
                          className="mask-group"
                          alt="Mask group"
                          src="/img/mask-group-1.png"
                        />
                        <div className="rectangle-5" />
                        <div className="frame-13">
                          <div className="text-wrapper-32">
                            Faisal Wirakusuma
                          </div>
                          <div className="text-wrapper-33">
                            Machine Learning Engineer
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-7">
                      <div className="overlap-group-4">
                        <img
                          className="mask-group"
                          alt="Mask group"
                          src="/img/mask-group-2.png"
                        />
                        <div className="rectangle-5" />
                        <div className="frame-13">
                          <div className="text-wrapper-32">Alex Rudi</div>
                          <div className="text-wrapper-33">
                            Finance Specialist
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Element>
            </div>
          </div>
          <div className="asset-3">
            <img
              className="ellipse-13"
              alt="Ellipse"
              src="/img/ellipse-24-1.svg"
            />
            <img
              className="ellipse-14"
              alt="Ellipse"
              src="/img/ellipse-24-1.svg"
            />
            <img className="img-6" alt="Img" src="/img/4.png" />
            <img className="img-3" alt="Img" src="/img/7.svg" />
            <img className="img-7" alt="Img" src="/img/7.svg" />
            <img className="img-5" alt="Img" src="/img/7.svg" />
          </div>
        </div>
        <div className="group-8">
          <div className="overlap-8">
            <Element name="contact" className="element">
              <div className="rectangle-6" />
              <div className="text-wrapper-34">Contact Us!</div>
              <p className="text-wrapper-35">
                Contact our technical experts for customer support.
              </p>
              <div className="group-9">
                <div className="overlap-group-5">
                  <img
                    className="feather-alt"
                    alt="Feather alt"
                    src="/img/feather-alt-1.svg"
                  />
                  <div className="text-wrapper-36">Contact Us</div>
                </div>
              </div>
            </Element>
            <img className="frame-14" alt="Frame" src="/img/frame.svg" />
          </div>
        </div>
        <div className="nav-bar">
          <Logo className="logo-instance" />
          <div className="navbar">
            <Link
              offset={-60}
              to="home"
              style={{ cursor: "pointer" }}
              smooth={true}
              className="text-wrapper-37"
            >
              Home
            </Link>
            <Link
              offset={-130}
              to="clients"
              style={{ cursor: "pointer" }}
              smooth={true}
              className="text-wrapper-37"
            >
              Our Clients
            </Link>
            <Link
              offset={-50}
              to="solutions"
              style={{ cursor: "pointer" }}
              smooth={true}
              className="text-wrapper-37"
            >
              Solutions
            </Link>
            <Link
              to="team"
              offset={-100}
              style={{ cursor: "pointer" }}
              smooth={true}
              className="text-wrapper-37"
            >
              Our Team
            </Link>
            <Link
              to="contact"
              offset={-120}
              style={{ cursor: "pointer" }}
              smooth={true}
              className="text-wrapper-37"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
